// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-asesoramiento-js": () => import("./../../src/pages/asesoramiento.js" /* webpackChunkName: "component---src-pages-asesoramiento-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-llave-en-mano-js": () => import("./../../src/pages/llave-en-mano.js" /* webpackChunkName: "component---src-pages-llave-en-mano-js" */),
  "component---src-pages-prensa-js": () => import("./../../src/pages/prensa.js" /* webpackChunkName: "component---src-pages-prensa-js" */),
  "component---src-pages-projects-js": () => import("./../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-proyecto-online-js": () => import("./../../src/pages/proyecto-online.js" /* webpackChunkName: "component---src-pages-proyecto-online-js" */),
  "component---src-pages-success-js": () => import("./../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-templates-project-js": () => import("./../../src/templates/project.js" /* webpackChunkName: "component---src-templates-project-js" */)
}

